






import { Component, Vue, Watch } from "vue-property-decorator";
import "@/components/al/ImportJs";
import { Action, Getter } from "vuex-class";
import langen from "element-ui/lib/locale/lang/en";
import langzh from "element-ui/lib/locale/lang/zh-CN";
import locale from "element-ui/lib/locale";
import "@/test"

@Component({})
export default class App extends Vue {
    @Action("getSetToken") setTokenCommit: any;
    @Action("getSetUserId") setUserId: any;
    @Action("getSetLang") setLangCommit: any;
    @Getter("getLang") lang: any;
    @Action("getSetUser") setUser: any;

    isShow: boolean = true;


    // 页面加载时如果sessionstorage存有token，存入vuex中
    setToken() {
        if (sessionStorage.getItem("api_token")) {
            this.setTokenCommit(sessionStorage.getItem("api_token"));
        }
        if (sessionStorage.getItem("id")) {
            this.setUserId(sessionStorage.getItem("id"));
        }

        if (
            sessionStorage.getItem("api_token") &&
            sessionStorage.getItem("id")
        ) {
            const getopt = this.$api.apiList.getUserInfo;
            const opt = getopt();
            this.$axios({
                url: `${opt.url}`,
                method: opt.method,
                headers: opt.headers
            }).then((res: any) => {
                this.setUser(res.data);
            });
        }
    }

    setLang() {
        if (sessionStorage.getItem("lang")) {
            this.setLangCommit(sessionStorage.getItem("lang"));
        } else {
            sessionStorage.setItem("lang", "zh");
            this.setLangCommit(sessionStorage.getItem("lang"));
        }
    }

    toggleShow() {
        this.isShow = false;
        setTimeout(() => {
            this.isShow = true;
        }, 10);
    }

    @Watch("lang")
    onChangeLang(nval: any, oval: any) {
        nval == "en" ? locale.use(langen) : locale.use(langzh);
        this.toggleShow()
    }

    mounted() {
        this.setToken();
        this.setLang();
    }

}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios/index';
import api from './api/index';
import i18n from './lang';

import {
  Loading, Dialog, Button, Icon, MessageBox, Message, Notification
} from "element-ui";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';


Vue.use(Loading.directive)
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Icon);

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;

Vue.config.productionTip = false

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)


//校验token
const testToken = async (to: any, from: any, next: any) => {
  let token = sessionStorage.getItem("api_token");
  // console.log(to)
  if (!token && to.name != "login" && to.name != "register" && to.name != "register-plain" && to.name != "register-expert" && to.name != "home") {
    //没有token，也不是路由去不需token的页面，跳转到login
    next({ name: "login" })
    // return { name: "login" }
  } else if (!token && to.name == "register" && to.params.step !== "0") {
    //没有token，路由去注册页但不是第一步，跳转到第一步
    next({ name: "register", params: { step: "0" } })
    // return { name: "register", params: { step: "0" } }
  } else if (!token && to.name == "register-expert" && to.params.step !== "0") {
    //没有token，路由去专家注册页但不是第一步，跳转到第一步
    next({ name: "register-expert", params: { step: "0" } })
  }
  else if (token && to.name == "login") {
    //有token，路由登录页，跳转到首页
    next({ name: "userinfo" })
    // next('/')
    // return { path: "/" }
  } else if (token && to.name == "register-plain") {
    //有token，路由账号注册，跳转到首页
    next({ name: "userinfo" })
    // next("/")
    // return { path: "/" }
  } else if (token && to.name == "register") {
    //有token，路由到项目注册，如果去到第一步（注册或登录账号），跳转到第二步
    if (to.params.step === "0") {
      next({ name: "register", params: { step: "1" } })
      // return { name: "register", params: { step: "1" } }
    } else {
      next()
      // return false
    }
  } else if (token && to.name == "register-expert") {
    //有token，路由到专家注册，如果去到第一步（注册或登录账号），跳转到第二步
    if (to.params.step === "0") {
      next({ name: "register-expert", params: { step: "1" } })
    } else {
      next()

    }
  } else {
    next()
    // return false
  }
}


router.beforeEach((to: any, from: any, next: any) => {
  // console.log(to)
  // next()
  if (to.meta.noToken) {
    next()
  } else {
    testToken(to, from, next).then((res: any) => {

    });
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

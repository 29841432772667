import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../lang';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: "",
    token: "",
    user: {},
    lang: "",
    assessPermission: []
  },

  getters: {
    getToken(state: any) {
      return state.token
    },
    getUserId(state: any) {
      return state.id
    },

    getUser(state: any) {
      return (key?: any) => {
        if (key) {
          return state.user[key]
        } else {
          return state.user
        }
      }
    },

    getLang(state: any) {
      return state.lang;
    },

    getAssessPermission(state: any) {
      return state.assessPermission
    }

    // getFile(state: any) {
    //   return function (id: any) {
    //     return state.fileList.filter((item: any) => item.id === id)[0]
    //   }
    // }
  },

  mutations: {
    setToken(state: any, data: any) {
      state.token = data
    },
    setUserId(state: any, data: any) {
      state.id = data;
    },
    setLang(state: any, data: any) {
      // console.log(`传进store的lang:${data}`)
      state.lang = data;
      // console.log(data)
      i18n.locale = state.lang
      // console.log(`语言改变后的session:${sessionStorage.getItem('lang')}`)
      // console.log(`store:${state.lang}`)
    },

    setUser(state: any, data: any) {
      state.user = data
    },

    setAssessPermission(state: any, data: any) {
      state.assessPermission = data
    }


  },

  actions: {
    getSetToken(context: any, data: any) {
      context.commit('setToken', data)
    },
    getSetUserId(context: any, data: any) {
      context.commit('setUserId', data)
    },
    getSetLang(context: any, data: any) {
      context.commit('setAssessPermission', [])
      context.commit('setLang', data)
    },
    getSetUser(context: any, data: any) {
      context.commit(`setUser`, data)
    },
    getSetAssessPermission(context: any, data: any) {
      context.commit(`setAssessPermission`, data)
    },

    clear(context: any, data?: any) {
      context.commit(`setUser`, {});
      context.commit(`setAssessPermission`, {});
      context.commit(`setToken`, "");
      context.commit(`setUserId`, "");

    }
  },
  modules: {
  }
})

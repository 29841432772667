import Vue from 'vue'

Vue.component('remote-script', {
    render: function (createElement) {
        var self = this;
        return createElement('script', {
            attrs: {
                type: 'text/javascript',
                src: this.src
            },

            on: {
                //外部文件加载完毕回调
                load: function (event: any) {
                    // console.log("文件加载完毕");
                    self.$emit('load', event);
                },

                //外部文件加载失败回调
                error: function (event: any) {
                    self.$emit('error', event);
                },

                //IE6-7 外部文件加载完毕回调
                readystatechange: function (event: any) {
                    console.log(event)
                    //@ts-ignore
                    if (this.readyState == 'complete') {
                        self.$emit('load', event);
                    }
                }
            }
        });
    },
    props: {
        src: {
            type: String,
            required: true
        }
    }
});
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'


Vue.use(VueI18n)

const DEFAULT_LANG: string = 'zh'
const LOCALE_KEY: string = 'lang'

const locales: any = {
    zh: require('../lang/zh.json'),
    en: require('../lang/en.json'),
}

const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    messages: locales,
})

const setup = (lang?: any) => {
    if (lang === undefined) {
        lang = window.sessionStorage.getItem(LOCALE_KEY)
        if (locales[lang] === undefined) {
            lang = DEFAULT_LANG
        }
    }

    window.sessionStorage.setItem(LOCALE_KEY, lang)

    Object.keys(locales).forEach(lang => {
        document.body.classList.remove(`lang-${lang}`)
    })
    document.body.classList.add(`lang-${lang}`)
    document.body.setAttribute('lang', lang)

    // Vue.config.lang = lang
    i18n.locale = lang
}

// setup()
export default i18n
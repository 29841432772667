import i18n from "../lang"
const baseUrl: string = "http://ittn_data.ittn.com.cn/api";
const besttt: string = "besttt"

const apiList: any = {

    //获取案例列表
    getCaseList(type_id:any){
        let opt = {
            url: `${baseUrl}/${besttt}/pagelist/${type_id}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取案例详情
    getCaseDetail(case_id:string){
        let opt = {
            url: `${baseUrl}/${besttt}/pagedetail/${case_id}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },


    //首页获取征集成果
    getRegistData() {
        let opt = {
            url: `${baseUrl}/${besttt}/collectionprogress`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取首页carousel
    getCarousel() {
        let opt = {
            url: `${baseUrl}/${besttt}/banner`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //百佳注册获取类型列表
    getTypeListt() {
        let opt = {
            url: `${baseUrl}/${besttt}/typelist`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    //新增项目
    uploadProjectBasic() {
        let opt = {
            url: `${baseUrl}/${besttt}/case`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //更新项目基本信息
    updateProjectBasic(pid: any) {
        let opt = {
            url: `${baseUrl}/${besttt}/case/update/${pid}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },


    // 登录
    login() {
        let opt = {
            url: `${baseUrl}/login`,
            method: "post",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //专家登录
    loginExpert(){
        let opt = {
            url: `${baseUrl}/besttt/login`,
            method: "post",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取评审项目列表
    getAssessList(eid: any, review_id?: any) {
        let opt = {
            url: `${baseUrl}/${besttt}/score/list/${eid}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },


    //获取评审项目
    getAssessProject(record_id: any) {
        // console.log(record_id);

        let opt = {
            url: `${baseUrl}/${besttt}/score/detail/${record_id}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    

    //提交评审分数
    uploadScore(record_id: any) {
        let opt = {
            url: `${baseUrl}/${besttt}/score/detail/${record_id}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },


    //注册
    regist() {
        let opt = {
            url: `${baseUrl}/register`,
            method: "post",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //上传图片
    uploadImg() {
        let opt = {
            url: `${baseUrl}/uploadimage`,
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //上传文件
    uploadFile() {
        let opt = {
            url: `${baseUrl}/uploadfile`,
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },



    //提交项目技术信息
    uploadProjectTech(pid: any) {
        let opt = {
            url: `${baseUrl}/project_1/${pid}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 提交项目成员信息
    uploadProjectTeam(pid: any) {
        let opt = {
            url: `${baseUrl}/projectteam/${pid}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //提交项目附件信息
    uploadProjectFile() {
        let opt = {
            url: `${baseUrl}/projectfile`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取项目注册领域国别等参数 
    getProjectOpt() {
        let opt = {
            url: `${baseUrl}/project_type`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    //获取用户信息
    getUserInfo() {
        let opt = {
            url: `${baseUrl}/user`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    // 根据渠道码获取渠道id
    getChannel(id: any) {
        let opt = {
            url: `${baseUrl}/channel/${id}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },



    /*
     *  更新项目技术信息接口
     *  但本质上等同于添加技术信息，两个接口可以通用
     */
    updateProjectTech(pid: any) {
        let opt = {
            url: `${baseUrl}/project/${pid}/update`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //批量更新项目技术信息
    updateProjectTeam(pid: any) {
        let opt = {
            url: `${baseUrl}/projectteam/${pid}/updatemany`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 删除项目文件
    delProjectFile(id: any) {
        let opt = {
            url: `${baseUrl}/projectfile/${id}/destroy`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 提交专家信息
    uploadExpertBasic() {
        let opt = {
            url: `${baseUrl}/expert`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 更新专家信息
    updateExpertBasic(id: any) {
        let opt = {
            url: `${baseUrl}/expert/${id}/update`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取评审权限
    getAssessPermission(eid: any) {
        let opt = {
            url: `${baseUrl}/review/permission/${eid}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },


    


    //往届回顾 —— 2020百强项目领域
    field_2020() {
        let opt = {
            url: "http://ittn.com.cn/api/itc_type",
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    projectList_2020(field: any) {
        let opt = {
            url: `http://ittn.com.cn/api/itc_plist/${field}`,
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    project_2020(pid: any) {
        let opt = {
            url: `http://ittn.com.cn/api/itc_pdetail/${pid}`,
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    expertList_2020() {
        let opt = {
            url: `http://ittn.com.cn/api/itc_elist`,
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    expert_2020(eid: any) {
        let opt = {
            url: `http://ittn.com.cn/api/itc_edetail/${eid}`,
            method: "get"
        }
        return opt;
    },


    // 获取邮箱验证码
    emailCaptha: {
        url: "http://api.gt-itcp.com/api/emailcaptha",
        method: "get"
    },

    // 获取渠道信息
    channel: {
        url: "http://api.gt-itcp.com/api/thirdpartner",
        method: "get"
    },





}

export default {
    apiList,
}